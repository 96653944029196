import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="China & International" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-14">
        <div class="format_text">
          <h1>China &amp; International</h1>
          <p>
            <span
              style={{
                float: "left",
                margin: "0 0.5em 0.5em 0.5em",
                textAlign: "left",
                display: "block",
              }}
            >
              <StaticImage
                src="../images/shutterstock_3579641-International-300x225.jpeg"
                alt="Boat off the coast"
              />
            </span>
            Convinced of the prominent role to be played by The People’s
            Republic of China during coming years, China is of particular
            interest to Mr. Trautman. During 2007, he studied law at Nankai
            University, Tianjin, China and has maintained a particular interest
            in Chinese securities markets and securities issuers who conduct
            most or all of their business in China. He is the author of law
            journal articles on international law and business law, including,
            American Entrepreneur in China: Potholes on the Silk Road to
            Prosperity, 12 Wake Forest Journal of Business &amp; Intellectual
            Property Law 427 (2012), available at{" "}
            <a href="http://www.ssrn.com/abstract=1995076">
              http://www.ssrn.com/abstract=1995076
            </a>
            .
          </p>
          <p>
            International sales play an ever increasing role for U.S. based
            companies. Yet many American boards lack the benefit of directors
            who are experienced in the culture, market channels and the
            substantial legal, economic, and governmental risk associated with
            doing business abroad. For these reasons, governance and nominating
            committees are increasingly seeking to add directors that have
            knowledge and experience in the international markets where they are
            either now doing business or expect to look for future growth. The
            magnitude of exposure from Foreign Corrupt Practice Act violations
            alone requires heightened sensitivity to this issue on the part of
            all boards conducting business in other parts of the world.
          </p>
          <p>
            Larry Trautman attended college in Washington, DC with a view toward
            a career as a diplomat and engaged in international relations. His
            interest in international commerce and international peace has
            continued over the years. He studied law at the University of Buenos
            Aires, Argentina (2008) and writes extensively about issues of
            international business and national security.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
